import React from 'react'
import {
  Timeline,
  Events,
  ImageEvent,
  TextEvent,
} from '@merc/react-timeline';
import { story1, story2, story3, story4, story5, story6, story7, story8, story9 } from "../../images";

export function StoryTimeline() {
  return (
    <Timeline>
      <Events>
        <ImageEvent
          date="1976"
          text="Study Links 的真實歷史可以追溯到 1976 年，當時來自香港的年輕學生 Eric Fan 移居英國，開始了他自己在英國的留學生涯。
          "
          src={story1}
        >
        </ImageEvent>
        <ImageEvent
          date="1976"
          text="Eric 開始在一所六年級大學學習，在那裏他遇到了 Angela。他們在讀 A-Level 課程，然後大學畢業。
          "
          src={story2}
        >
        </ImageEvent>
        <ImageEvent
          date="1983"
          text="1983 年，這對年輕夫婦移居香港，在那裏 Eric 教授數學和計算機科學，Angela 在學校教英語，Angela 會說流利的粵語，他們婚後育有兩個孩子。
          "
          src={story3}
        >
        </ImageEvent>
        <ImageEvent
          date="1988"
          text="在香港呆了 5 年後，爲了讓他們的孩子接受英國教育，這對夫婦決定搬回英國。Eric 在布拉德福德的一家大公司找到了一份工作，他們有了第三個孩子 Ailie。
          "
          src={story6}
        >
        </ImageEvent>
        <ImageEvent
          date="1989"
          text="Eric 的侄子 Andy 想來英國學習，所以他們給 Andy 找到了一所很好的寄宿學校，Andy 在假期會和他們一起住。這是他們第一次作爲監護人的經歷。
          "
          src={story7}
        >
        </ImageEvent>
        <ImageEvent
          date="1990-98"
          text="由於這次作爲監護人非常成功，第二年，他的妹妹 Carmen 和家人朋友的兩個孩子來到由這對夫婦作爲監護人所選的學校學習。Angela 繼續在當地一所 FE 學院爲外國學生教授英語，並代表學院到世界各地招收學生到英國學習。在 90 年代，越來越多的朋友、家人、熟人和朋友的朋友來到英國，Angela 和 Eric 繼續幫助他們尋找合適的學校並充當他們的監護人。確保所有學生都快樂和安全是一項非常有意義但又很辛苦的工作。看到學生們在他們的幫助下不斷進步和茁壯成長，以及學生的父母對他們的感激之情，他們感到很欣慰。
          "
          src={story8}
        >
        </ImageEvent>
        <TextEvent
          date="1998"
          text="隨着收到越來越多的監護請求，他們於 1998 年決定成立自己的監護公司，在他們家中開始了運營之路。由於他們自己無法容納所有的學生，他們要求朋友和家庭成員爲這些主要在約克郡地區的寄宿學校讀書的學生提供家庭寄宿，讓他們能夠在溫馨的充滿關懷的家庭中生活。"
        />
        <TextEvent
          date="2008"
          text="公司迅速擴大了規模並在英國各地的家庭和學校中享有聲譽，到 2008 年，Study Links 擁有自己的營業場所，僱用了五名全職員工，並擁有更多的寄宿家庭。"
        />
        <TextEvent
          date="2013"
          text="2013 年，我們在英國駐北京大使館舉辦了“英國教育”活動，特邀業內人士擔任演講嘉賓。其目的是推廣英國教育體系，來自中國各地的精英學校、企業、家長和學生參加了該活動。該活動得到了很好的反響，媒體和電視都進行了積極的報道。"
        />
        <ImageEvent
          date="現在"
          text="公司不斷髮展壯大，在英國各地的學校擁有來自多個國家的監護學生，以及一個由兼職員工和寄宿家庭構成的網絡。他們的女兒愛莉學習過中文和商業，如今在企業中擔任重要職位。
          "
          src={story9}
        >
        </ImageEvent>
      </Events>
    </Timeline>
  );
}

