import React from 'react'
import styled from 'styled-components'

const Indented = styled.div `
  margin-left: 20px;
`

const TestimonialAccordion = () => {
  return (
    <div id="accordion">
      <div class="card">
        <div class="card-header" id="headingOne">
          <h5 class="mb-0">
            <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">         
              Dominic Wang – 利茲英語語言學校、聖母大學天主教高中和牛津大學的學生。
            </button>
          </h5>
        </div>

        <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
          <div class="card-body">
          “在我生命中最黑暗的日子裏，Study Links 幫助了我，當時我還是一個無助的孩子，剛到外國，對語言知之甚少，總擔心會發生什麼。在最初的幾個月裏，Angela 和她的家人充當了我的嚮導和導師，熱情地向我介紹他們的語言和文化。他們讓我有了歡笑和喜悅，而不再是孤獨和悲傷，使我可以有信心去追求自己內心所渴望的東西。我的寄宿家庭太好了，在和他們住了整整一年之後，我離開了他們，感到非常難過。我的監護人 Angela 每隔幾個月就會帶我出去看看我對自己的生活是否滿意。“學校和我所期望的一樣好。我的同學都是來自世界各地的不同種族，老師們隨時都在那裏提供幫助。正是在這些可愛的人的幫助下，我在很短的時間內就在語言上取得了很大的進步，並有足夠的信心與我的英國同學競爭。“非常感謝你， Study Links如果沒有 Study Links，就不會有今天的我。我向你們致以良好的祝願，我很高興看到越來越多的學生得到我曾經擁有的那份幸運和幫助。”
<a href="https://www.youtube.com/watch?v=BfmWCi1OqU4" target="_blank"> 視頻</a>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingTwo">
          <h5 class="mb-0">
            <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
Vivian Chung，Grace Chung 的父母
            </button>
          </h5>
        </div>
        <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
          <div class="card-body">
          “我的女兒現在已度過了由 Study Links 監護的第一年。我想表達我最深切的謝意，感謝你們爲我和我的女兒 Grace 介紹學校和一個很棒的寄宿家庭。儘管我女兒在適應英國和學校時有些困難，但今年女兒在學校的學習和生活上表現出乎意料的好。這要歸因於：
        <br/>
        <br/>
          <ul>
            <li>  運用知識和同理心提供最周到安排的 Study Links 專業監護人團隊。</li>
            <li>阿克沃斯學校良好的學術和行政基礎。那裏的工作人員和教師所具備的一貫關懷和專業精神。</li>
          </ul>
          像這樣的短消息只能表達我一小部分的感激之情。
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingThree">
          <h5 class="mb-0">
            <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
Wong 先生，Samson 和 Samuel Wong 的家長
            </button>
          </h5>
        </div>
        <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
          <div className="card-body">
          “我的兩個兒子在英國上學期間，Study Links 在照顧他們。每當我的孩子們遇到任何問題，Study Links 團隊都會提供幫助，提供有價值、貼心的建議和切實可行的解決方案。自從我的兒子離開英國後，我們一直與 Angela 和她的家人保持聯繫，我們仍然是很好的朋友。”
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingFour">
          <h5 class="mb-0">
            <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
Chen 太太，Linda 和 Sam Chen 的家長
            </button>
          </h5>
        </div>
        <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordion">
          <div class="card-body">
          “在我們的孩子在英國期間都得到了 Angela 和 Study Links 的大力支持。Study Links 與我們的孩子和我們都保持着定期聯繫，因此我們在任何時候都可以完全放心。Angela 和 Study Links 像照顧自己的孩子一樣照顧我的孩子，對此我十分感激。他們由 Study Links 的照顧，我完全不用擔心他們的安全。我總是向任何想把孩子送到英國的家長推薦他們的服務。”
          </div>
        </div>
      </div>
      
      
    </div>
  )
}

export default TestimonialAccordion