import React from 'react'
import styled from 'styled-components'
import {Button} from "../ButtonElement"
import Fade from "react-reveal/Fade"
// import {borderRadius, buttonStyle} from 'styled-system'

const CardBackground = styled.div `
width: 14rem;
border-radius: 10px;
background-color: #0B0040;
box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
margin: 10px;
`

export const Card = (props) => {
const {title, text, buttonText, buttonLink, img, imgwidth, imgheight} = props
return (
  <Fade>
    <CardBackground>
      <img class="card-img-top" src={img} width={imgwidth} height={imgheight} style={{borderRadius:"10px"}}/>
      <div class="card-body">
        <h5 class="card-title" style={{color: 'white', textAlign: 'center'}}>{title}</h5>
        <p class="card-text" style={{color: 'white', textAlign: 'center'}}>{text}</p>
        {buttonText!=null ? <Button to={buttonLink}>{buttonText}</Button> : null}
      </div>
    </CardBackground>
  </Fade>
)
}
