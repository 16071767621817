import React from "react"
import {
  ItemContainer,
  ItemText,
  ItemImageContainer,
  ItemImage,
  ItemImageDescription,
  ItemTitle,
  TextContainer,
  BlogLink,
  MainContainer,
} from "./elements"
import {
  award1,
  award2,
  award3,
  award4,
  award5,
  award6,
  award7,
  award8,
  award9,
  family_business,
  logo,
  accreditations1,
  accreditations2,
  accreditations3,
  accreditations4,
  accreditations5,
  accreditations6,
  accreditations7,
  accreditations8,
  CEOToday,
  AIExcellence,
} from "../../images"
import Fade from "react-reveal/Fade"

const Item = props => {
  const { imageSource, title, text, description, link, bshadow } = props
  return (
    <Fade>
      <ItemContainer>
        <TextContainer>
          <ItemTitle>{title}</ItemTitle>
          <ItemText>{text}</ItemText>
        </TextContainer>
        <ItemImageContainer>
          <ItemImage src={imageSource} bshadow={bshadow} />
          <ItemImageDescription>{description}</ItemImageDescription>
        </ItemImageContainer>
      </ItemContainer>
    </Fade>
  )
}

export const Accreditations = () => {
  return (
    <MainContainer>
      <Item
        text="Study Links 是英国安全学校倡议 (Safe Schools UK initiative) 的成员 - 英国安全学校倡议是在英国政府向全国宣布结束封锁的路线图后创建的。创建英国安全学校倡议是为了让学校能够快速、清晰地向学生、家长及其社区传达将要实施的安全措施。"
        imageSource={accreditations1}
        description=""
        title="安全学校倡议"
        link="/"
      />
      <Item
        text="Study Links 由国际学生教育和监护协会 AEGIS 完全认证。我们的所有流程都由 AEGIS 定期检查和审核，以确保我们为所照顾的学生提供最高水平的服务。"
        imageSource={accreditations2}
        description=""
        title="国际学生教育和监护协会"
        link="/"
      />
      <Item
        text="Study Links 被认证为 Living Wage 雇主。Living Wage 是独立设定的小时工资，每年更新一次。Living Wage 是根据英国的基本生活成本计算得出的。雇主选择自愿支付 Living Wage。在首相和反对派领导人的公开支持下，Living Wage 得到了跨党派的支持。Living Wage 基金会认可并赞扬英国各地的 Living Wage 雇主所表现出的领导力。目前有超过 1,200 名经认证的雇主。"
        imageSource={accreditations3}
        description=""
        title="Living Wage 雇主"
        link="/"
      />
      <Item
        text="Study Links 是 FSB（英国小企业联合会）的成员。FSB 是英国主流的商业组织，我们一直在为小型企业提供支持。"
        imageSource={accreditations4}
        description=""
        title="英国小企业联合会"
        link="/"
      />
      <Item
        text="Study Links 是布拉德福德商会的成员。布拉德福德商会为该地区的所有企业提供支持服务。他们帮助企业开拓新市场，并确保地方和国家政府内部人员能够听取他们的意见。"
        imageSource={accreditations5}
        description=""
        title="布拉德福德商会"
        link="/"
      />
    </MainContainer>
  )
}

export const Awards = () => {
  return (
    <MainContainer>
      <Item
        text="Study Links 赢得了 2021 年《今日 CEO》杂志全球奖，该奖项旨在认可、确定并表彰当今全球最受尊敬的公司及高管。"
        imageSource={CEOToday}
        description=""
        title="2021 年《今日 CEO》杂志全球奖"
        link="/"
        bshadow="true"
      />
      <Item
        text=""
        imageSource={family_business}
        description=""
        title="2019 年度家族企业（入围）"
        bshadow="true"
      />
      <Item
        text=""
        imageSource={logo}
        description=""
        title="2019 年度出口团队（入围）"
        bshadow="true"
      />
      <Item
        text=""
        imageSource={AIExcellence}
        description=""
        title="最佳沉浸式语言教学公司 - 英国"
        bshadow="true"
      />
      <Item
        text=""
        imageSource={AIExcellence}
        description=""
        title="2017 年沉浸式英语教学 AI 卓越奖"
        bshadow="true"
      />
      <Item
        text=""
        imageSource={award1}
        description=""
        title="2017 年 AI 影响力女企业家奖"
        bshadow="true"
      />
      <Item
        text="Study Links 入围 Telegraph and Argus Bradford Means Business 奖 2016 年度最佳雇主。很遗憾，我们与 JCT600 失之交臂，但我们受到了高度赞扬。
        "
        imageSource={award2}
        description=""
        title="2016 年 Telegraph and Argus Bradford Means Business 奖（高度赞扬）"
        bshadow="true"
      />
      <Item
        text=""
        imageSource={award3}
        description=""
        title="2016 年约克郡人民选择奖"
        bshadow="true"
      />
      <Item
        text="我们的创始人 Angela Fan 于 2016 年在兰迪德诺（威尔士）举办的 Network She Foundation 国际妇女节颁奖典礼上荣获女性教育奖"
        imageSource={award6}
        description=""
        title="2016 年妇女教育奖"
        bshadow="true"
      />
      <Item
        text="Study Links 入选 2014 年“已建立的对华业务”类别中的约克郡-亨伯中国商业奖。约克郡-亨伯中国商业奖由英国利兹大学孔子学院主办，由英国贸易投资总署和中英商务委员会支持。
        "
        imageSource={award9}
        description=""
        title="2014 年约克郡-亨伯中国商业奖（入围）"
        bshadow="true"
      />
    </MainContainer>
  )
}
