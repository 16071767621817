import React from "react"
import { graphql } from "gatsby"
import Layout from "../layout/layout"
import styled from "styled-components"
import Poster from "../images/Poster.png"
import { fontSize, textAlign } from "styled-system"
import Fade from "react-reveal/Fade"
import {
  AngelaFan,
  AilieFan,
  EricFan,
  JodieDriver,
  DianneSenior,
  ZoePilling,
  YueruiSun,
  GabrielaSeczkowska,
  SarahSingleton,
  PaulaJureczeko,
  AnnieWang,
  aboutBanner,
  AnnAustin,
  BridgetLane,
  BryanChadderton,
  ChrisHolsman,
  ClareBakerSmith,
  DenisSavage,
  HarrietJohnsonTsui,
  KatieSchad,
  LouiseHarrison,
  MichaelGrive,
  NataljaFakirova,
  NikMacKenzie,
  PhilDavis,
  TamsinBailey,
  YingZhang,
  BlankPortrait,
  ChristineKwong,
} from "../images"
import { TeamContainer, TeamSubheading } from "../containers/Team"
import { Cards, Card } from "../components/TeamCards"
import ReactHtmlParser from "react-html-parser"
import { Awards, Accreditations } from "../containers/Awards&Accreditations"
import { StoryTimeline } from "../components/Timeline"
import { AboutTestimony } from "../components/Testimonials"
import TestimonialAccordion from "../components/Accordions/TestimonialAccordion"
import SEO from "../components/seo"

export const Block = styled.div`
  height: 400px;
  background-color: black;
`

export const FadeDivContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 10px;
`

const FadeParagraphContainer = styled.p`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  word-wrap: normal;
  text-align: center;
`

const FadeParagraph = ({ children }) => {
  return (
    <FadeDivContainer>
      <Fade>
        <FadeParagraphContainer>{children}</FadeParagraphContainer>
      </Fade>
    </FadeDivContainer>
  )
}

const FadeImage = props => {
  const { image, description } = props
  return (
    <Fade>
      <img
        src={image}
        style={{
          borderRadius: "10px",
          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        }}
      />
      <div
        style={{
          fontFamily: "sans-serif",
          marginTop: "20px",
          marginBottom: "50px",
          textAlign: "left",
        }}
      >
        {description}
      </div>
    </Fade>
  )
}

const FadeH1 = ({ children }) => {
  return (
    <div style={{ position: "relative", textAlign: "center" }}>
      <Fade>
        <h1>{children}</h1>
      </Fade>
    </div>
  )
}

const Section = styled.div`
  margin: 40px 230px;
  max-width: 100%;

  @media (max-width: 1200px) {
    margin: 100px;
  }
  @media (max-width: 740px) {
    margin: 11px;
  }
`

const sidebar = [
  {
    to: "aboutUs",
    title: "關於我們",
  },
  {
    to: "ourTeam",
    title: "我們的團隊",
  },
  {
    to: "ourStory",
    title: "我們的故事",
  },
  {
    to: "ourAchievements",
    title: "獎項及認可",
  },
  {
    to: "Testimonials",
    title: "口碑",
  },
  {
    to: "ourAccreditations",
    title: "認證",
  },
]

export default function About({ data }) {
  const html = data.wpPage.content
  const processedData = ReactHtmlParser(html)
  return (
    <Layout
      sidebar={sidebar}
      pageTitle="關於我們"
      background={aboutBanner}
      pageDescription="我們經驗豐富，致力於幫助學生實現夢想。這是我們的故事…"
    >
      <SEO
        title="About | Guardianship | UK Education | Student Education"
        description="Study Links was founded in 1998 and recognised one of the UK’s leading and most established guardianship companies. Find out more about us. "
      />
      <Section id="aboutUs">
        <br />
        <FadeParagraph>
          Study Links 成立於 1998
          年，是一家家族企業，在業內獲獎無數，我們可以自豪地説，我們是英國最著名的監護公司之一。
        </FadeParagraph>
        <FadeParagraph>
          Study Links
          團隊在英國和其他國家/地區學習和教學的個人經驗有助於我們更好地找到支持學生的方法。
        </FadeParagraph>
      </Section>
      <Section id="ourTeam">
        <FadeH1>我們的團隊</FadeH1>
        <TeamContainer>
          <TeamSubheading>領導團隊</TeamSubheading>
          <Cards>
            <Card title="Angela Fan" text="創始人" img={AngelaFan} />
            <Card title="Eric Fan" text="主任" img={EricFan} />
            <Card
              title="Ailie Fan"
              text="常務董事
"
              img={AilieFan}
            />
          </Cards>
          <Cards>
            <Card
              title="Dianne Senior"
              text="運營主管
"
              img={DianneSenior}
            />
            <Card title="Zoe Pilling" text="監護主管" img={ZoePilling} />
          </Cards>
          <TeamSubheading>金融團隊</TeamSubheading>
          <Cards>
            <Card title="Sarah Singleton" text="會計" img={SarahSingleton} />
          </Cards>
          <TeamSubheading>營銷團隊</TeamSubheading>
          <Cards>
            <Card title="Lisa Howard" text="營銷主管" img={BlankPortrait} />
          </Cards>
          <TeamSubheading>運營團隊</TeamSubheading>
          <Cards>
            <Card title="Annie Wang" text="學生導師" img={AnnieWang} />
            <Card
              title="Gabriela Seczkowska"
              text="運營協調員
                "
              img={GabrielaSeczkowska}
            />
            <Card
              title="Jodie Driver"
              text="運營協調員
                "
              img={JodieDriver}
            />
          </Cards>
          <Cards>
            <Card
              title="Christine Kwong"
              text="運營協調員
                "
              img={ChristineKwong}
            />
            <Card title="Fiona Boalch" text="辦公管理" img={BlankPortrait} />
          </Cards>
          <Cards>
            <Card
              title="Paula Jureczko"
              text="運營協調員
                "
              img={PaulaJureczeko}
            />
            {/* <Card title="Yuerui Sun" text="學生導師" img={YueruiSun} /> */}
          </Cards>
          <TeamSubheading>學業顧問</TeamSubheading>
          <Cards>
            <Card title="Ann Austin" text="學術顧問" img={AnnAustin} />
            <Card title="Bridget Lane" text="學術顧問" img={BridgetLane} />
            <Card
              title="Bryan Chadderton"
              text="學術顧問"
              img={BryanChadderton}
            />
          </Cards>
          <Cards>
            <Card title="Chris Holsman" text="學術顧問" img={ChrisHolsman} />
            <Card
              title="Clare Baker-Smith"
              text="學術顧問"
              img={ClareBakerSmith}
            />
            <Card title="Denis Savage" text="學術顧問" img={DenisSavage} />
          </Cards>
          <Cards>
            <Card
              title="Harriet Johnson-Tsui"
              text="學術顧問"
              img={HarrietJohnsonTsui}
            />
            <Card title="Katie Schad" text="學術顧問" img={KatieSchad} />
            <Card
              title="Louise Harrison"
              text="學術顧問"
              img={LouiseHarrison}
            />
          </Cards>
          <Cards>
            <Card title="Michael Grive" text="學術顧問" img={MichaelGrive} />
            <Card
              title="Natalja Fakirova"
              text="學術顧問"
              img={NataljaFakirova}
            />
            <Card title="Nik MacKenzie" text="學術顧問" img={NikMacKenzie} />
          </Cards>
          <Cards>
            <Card title="Phil Davis" text="學術顧問" img={PhilDavis} />
            <Card title="Stuart Mills" text="學術顧問" img={BlankPortrait} />
            <Card title="Tamsin Bailey" text="學術顧問" img={TamsinBailey} />
          </Cards>
          <Cards>
            <Card title="Ying Zhang" text="學術顧問" img={YingZhang} />
          </Cards>
        </TeamContainer>
      </Section>
      <Section id="ourStory">
        <FadeH1>我們的故事</FadeH1>
        {/* {processedData.map((data, index) => {
            return (
              <Fade>
                <div className="storyData">{data.props.children}</div>
              </Fade>
            )
          })} */}
        <br />
        <Fade>
          Study Links International 由夫妻團隊 Eric 和 Angela Fan 於 1998
          年創立。在他們的辛勤工作、關懷和奉獻精神的驅動下，Study Links
          不斷成長，從一家由家族運營的企業發展成為如今一家仍以強烈家族精神為核心的、專注於提供優質服務的公司。
        </Fade>
        <br />
        <StoryTimeline />
        <br />
        <Fade>
          我們的公司不斷髮展壯大，在我們的領域內擁有豐富的知識和經驗，聲譽不斷得以提高。我們仍然忠於公司核心的家庭價值觀，讓受我們照顧的每個學生都從中受益。Study
          Links
          明白他們在英國的經歷對他們的未來很重要，我們幫助他們成功地度過這一階段的生活，並灌輸給他們信心、技能和工具，讓他們去順利開展下一階段的人生之旅。
        </Fade>
      </Section>
      <Section id="ourAchievements">
        <FadeH1>獎項及認可</FadeH1>
        <Awards />
      </Section>
      <Section id="Testimonials">
        <FadeH1>口碑</FadeH1>
        <TestimonialAccordion />
      </Section>
      <Section id="ourAccreditations">
        <FadeH1>我們的認證</FadeH1>
        <Accreditations />
      </Section>
      <AboutTestimony />
    </Layout>
  )
}

export const STUDYLINKSHISTORY = graphql`
  query StudyLinksHistory {
    wpPage(title: { eq: "api-studylinks-history" }) {
      title
      content
    }
  }
`
